import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { Router, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./fonts/Poppins/Poppins-Regular.ttf";
import "./index.css";

const TutorsScreen = lazy(() =>
	import("./screen/Tutors/TutorsScreen").then(({ TutorsScreen }) => ({
		default: TutorsScreen,
	}))
);
const HomeScreen = lazy(() =>
	import("./screen/Home/HomeScreen").then(({ HomeScreen }) => ({
		default: HomeScreen,
	}))
);
const TutorDetailsScreen = lazy(() =>
	import("./screen/Tutors/TutorDetails/TutorDetailsScreen").then(
		({ TutorDetailsScreen }) => ({
			default: TutorDetailsScreen,
		})
	)
);
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
	{
		path: "/",
		element: <HomeScreen />,
	},
	{
		path: "/tutores",
		element: <TutorsScreen />,
	},
	{
		path: "/tutores/detalhes",
		element: <TutorDetailsScreen />,
	},
]);

root.render(
	<React.StrictMode>
		<Suspense
			fallback={
				<div
					style={{
						display: "flex",
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
					}}
				>
					<span style={{ color: "#FFFFFF", fontSize: 20 }}>
						Carregando a sua melhor experiência...
					</span>
				</div>
			}
		>
			<RouterProvider router={router} />
		</Suspense>
	</React.StrictMode>
);
